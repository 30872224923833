import { useCallback, useContext, useEffect, useState } from 'react';
import FunnelContext from '../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { addDataLayer } from '../utils/dataLayer';
import { replaceUrlState } from '../utils/replaceUrlState';
import { getAuthToken } from '../utils/getAuthToken';

const useAuth = (
  petlabCampaignTokenKey = 'petlab_campaign_token',
  klaviyoUserIdKey = 'utm_id',
  klaviyoCampaignIdKey = 'utm_campaign',
  redirectIfAuthFails = false,
  expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
  useStagingApiEndpoint = false
) => {
  const { setCurrentLayer, setLoadingCover } = useContext(FunnelContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [token, setToken] = useState(null);

  const errorHandler = useCallback(
    error => {
      console.log(error);
      if (redirectIfAuthFails && !Builder.isEditing) {
        window.location.href = expiredTokenRedirect;
      } else {
        replaceUrlState(
          `${window.location.origin + window.location.pathname}?layer=error`
        );
        setCurrentLayer('error');
        setLoading(false);
        setError(true);
      }
    },
    [expiredTokenRedirect, redirectIfAuthFails, setCurrentLayer]
  );

  const getToken = useCallback(async () => {
    try {
      return await getAuthToken({
        useStagingApiEndpoint,
        klaviyoUserIdKey,
        petlabCampaignTokenKey,
        klaviyoCampaignIdKey
      });
    } catch (error) {
      if (Builder.isEditing) return error;

      addDataLayer('magic_link_login_failed_event');

      if (redirectIfAuthFails) {
        window.location.href = expiredTokenRedirect;
      } else {
        replaceUrlState(
          `${window.location.origin + window.location.pathname}?layer=error`
        );
        setLoadingCover(false);
      }
      return error;
    }
  }, [
    useStagingApiEndpoint,
    klaviyoCampaignIdKey,
    klaviyoUserIdKey,
    petlabCampaignTokenKey,
    expiredTokenRedirect,
    redirectIfAuthFails,
    setLoadingCover
  ]);

  useEffect(() => {
    try {
      getToken()
        .then(results => {
          results?.token && setToken(results.token);
          setLoading(false);
        })
        .catch(error => {
          errorHandler(error);
        });

      const url = typeof window !== 'undefined' ? window.location.search : '';
      const urlParams = new URLSearchParams(url);
      const isError = urlParams.get('layer') === 'error';

      if (isError) {
        errorHandler(error);
      }
    } catch (error) {
      errorHandler(error);
    }
  }, [setToken, getToken, errorHandler]);

  return { token, setToken, getToken, loading, error };
};

export default useAuth;
