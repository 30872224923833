import { getParamFromQuery } from './getParamFromQuery';
import axios from 'axios';

export const getAuthToken = async props => {
  const {
    useStagingApiEndpoint = false,
    klaviyoUserIdKey,
    petlabCampaignTokenKey,
    klaviyoCampaignIdKey
  } = props;
  const endpointApiSite = useStagingApiEndpoint
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  const petlabCampaignTokenValue = getParamFromQuery(petlabCampaignTokenKey);
  const klaviyoUserIdValue = getParamFromQuery(klaviyoUserIdKey);
  let klaviyoCampaignIdValue = getParamFromQuery(klaviyoCampaignIdKey).match(
    /\((.*)\)/g
  );

  if (klaviyoCampaignIdValue) {
    klaviyoCampaignIdValue = klaviyoCampaignIdValue[0] || '';
    klaviyoCampaignIdValue = klaviyoCampaignIdValue
      .replace('(', '')
      .replace(')', '');
  }

  if (
    !petlabCampaignTokenValue ||
    petlabCampaignTokenValue === '' ||
    !klaviyoUserIdValue ||
    klaviyoUserIdValue === '' ||
    !klaviyoCampaignIdValue ||
    klaviyoCampaignIdValue === ''
  ) {
    throw new Error({
      name: 'ReferenceError',
      message: 'invalid requirements'
    });
  }

  return await axios
    .post(`${endpointApiSite}/api/campaign/auth`, {
      petlab_campaign_token: petlabCampaignTokenValue,
      klaviyo_user_id: klaviyoUserIdValue,
      klaviyo_campaign_id: klaviyoCampaignIdValue
    })
    .then(({ data }) => {
      return JSON.parse(data);
    })
    .catch(error => {
      throw error;
    });
};
