import React, { useState, useCallback, useContext, useEffect } from 'react';
import { CountProvider } from '../../context/CountContext';
import { fetchUsersActiveSubscriptions } from '../../utils/activeSubscriptions';
import { replaceUrlState } from '../../utils/replaceUrlState';
import { getUserDetails } from '../../utils/user';
import FunnelContext from '../../context/FunnelContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Builder } from '@builder.io/react';
import LoadingCover from '../LoadingCover/LoadingCover';
import useAuth from '../../hooks/useAuth';

const RepurchaseContainer = props => {
  const { children } = props;

  const {
    subscriptionList,
    setSubscriptionList,
    setUserDetails,
    extraObjects,
    setExtraObjects,
    setCurrentLayer,
    setCurrentCategory
  } = useContext(FunnelContext);

  const { category = 'onetime' } = props;
  const [showLoading, setShowLoading] = useState(true);
  const { token, loading, error } = useAuth();

  const [, setTaxRate] = useLocalStorage(0);

  const getUserInfo = useCallback(async () => {
    setCurrentCategory(category);
    const activeSubscriptions = await fetchUsersActiveSubscriptions(token);
    if (!activeSubscriptions?.data) {
      replaceUrlState(
        `${window.location.origin + window.location.pathname}?layer=error`
      );
      setCurrentLayer('error');
      return;
    }
    const [firstItem = {}] = activeSubscriptions.data?.data || [];
    const { taxAmount, subscriptionAmount } = firstItem;
    await setTaxRate(taxAmount / subscriptionAmount);
    await setSubscriptionList(activeSubscriptions.data?.data);

    const userDetails = await getUserDetails({
      bearerToken: token
    });
    if (!userDetails) {
      replaceUrlState(
        `${window.location.origin + window.location.pathname}?layer=error`
      );
      setCurrentLayer('error');
      return;
    }
    setUserDetails(userDetails);

    setExtraObjects({
      ...extraObjects,
      firstname: firstItem?.shippingAddress.firstName
    });
  }, [
    token,
    extraObjects,
    setExtraObjects,
    setUserDetails,
    setTaxRate,
    setSubscriptionList,
    setCurrentLayer,
    category,
    setCurrentCategory
  ]);

  useEffect(() => {
    if ((!loading && subscriptionList?.length) || (!loading && error)) {
      setShowLoading(false);
    }
  }, [error, loading, subscriptionList?.length, setShowLoading]);

  useEffect(() => {
    if (!token || loading || subscriptionList?.length) return;
    void getUserInfo();
  }, [token, loading, getUserInfo, subscriptionList?.length]);

  useEffect(() => {
    setCurrentCategory(category);
  }, [category, setCurrentCategory]);

  if (showLoading && !Builder.isEditing) {
    return <LoadingCover />;
  }

  return <CountProvider>{children}</CountProvider>;
};

export default RepurchaseContainer;
